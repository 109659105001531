<template>
  <div class="section-sub-title-wrapper">
    {{title}}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    }
  },
  name: 'SectionSubTitle'
}
</script>

<style lang="less" scoped>
.section-sub-title-wrapper {
  margin-top: 1.0229166vw;
  font-size: 2.24081633vw;
  font-family:PuHuiTi;
  font-weight: bold;
  color: #171B1D;
  line-height: 1.1458333vw;
  letter-spacing: 1px;
}
   @media screen and (max-width: 980px) {
     .section-sub-title-wrapper {
      margin-top: 1.0229166vw;
      font-size: 2.24081633vw;
      font-family:PuHuiTi;
      font-weight: bold;
      color: #171B1D;
      line-height: 4.1458333vw;
      letter-spacing: 1px;
    }
   }
</style>
