<template>
  <div class="service_cully">
      <div class="service">
            <div class="service_title" id="leaveWrod">
                <SectionTitle title="service"/>
                <SectionSubTitle title="服务支持"/>
            </div>
            <div class="service_cont">
                <div class="contLeft">
                   <div class="contBox">
                        <div class="contlist">
                        <div class="listTitle">电话</div>
                        <div class="tell">
                            {{frimInfo.phone}}
                        </div>
                        </div>
                        <div class="contlist">
                            <div class="listTitle">邮箱</div>
                            <div class="listCont">
                                {{frimInfo.email}}
                            </div>
                        </div>
                   </div>
                    <div class="contlist">
                        <div class="listTitle">地址</div>
                        <div class="listCont">
                            {{frimInfo.location}}
                        </div>
                    </div>
                </div>
                <div class="contRight">
                    <div class="message_info">
                        <div class="userInfo">
                            <span>姓名*</span>
                            <el-input v-model="username" placeholder="请输入姓名"></el-input>
                        </div>
                        <div class="userInfo">
                            <span>邮箱*</span>
                            <el-input v-model="usereml" placeholder="请输入邮箱"></el-input>
                        </div>
                        <div class="userInfo">
                            <span>电话*</span>
                            <el-input v-model="usertel" placeholder="请输入电话"></el-input>
                        </div>
                    </div>
                    <div class="message_cont">
                        <div>内容</div>
                        <textarea placeholder="留言需审核后才会显示" cols="30" rows="10" v-model="contents"></textarea>
                    </div>
                    <div class="message_btn">
                        <button @click="btnLeave">提交留言</button>
                    </div>
                </div>
            </div>
      </div>
      <div class="cully">
          <div class="cully_title">
                <SectionTitle title="partner"/>
                <SectionSubTitle title="合作伙伴"/>
          </div>
          <div class="cullyList">
              <ul>
                  <li v-for="(item,index) in serviceArr" :key="index">
                      <img :src="item.images" alt="">
                  </li>
              </ul>
          </div>
      </div>
  </div>
</template>
<script>

import { imgHttp, request } from '../../apis/http'
import NavMixin from '@/mixins/nav-minxin'
export default {
  data () {
    return {
      input: '',
      serviceImg:'',
      serviceArr:[],
    //   留言
      username:'',
      usereml:'',
      usertel:'',
      contents:'',
    //   公司信息
      frimInfo:{}
    }
  },
  mixins: [
    NavMixin
  ],
  beforeMount() {
      this.service()
      this.frimsInfo()
  },
  methods:{
    //   合作伙伴
      async service(){
          const res = await request({
              url:'/api/index/cooperate'
          })
         const newArr = res.data.data.map(i => {
             i.images = imgHttp + i.images
             return i
         })
         this.serviceArr = newArr
      },
    //   公司信息
    async frimsInfo(){
          const res = await request({
              url:'/api/index/company'
          })
          this.frimInfo = res.data.data
      },
      btnLeave(){
          if (this.username === '' || this.usertel === '' || this.usereml === '' || this.contents === ''){
             this.$notify.error({
            title: '错误',
            message: '内容填写不完整'
           })
           return
          }
          this.leaveWrod()
      },
      async leaveWrod(){
          const res = await request({
              url:'/api/index/message',
              method:'post',
              data:{ name:this.username, phone:this.usertel, email:this.usereml, explain:this.contents }
          })
          if (res.status === 200){
              this.username = ''
              this.usertel = ''
              this.usereml = ''
              this.contents = ''
               this.$notify({
                title: '成功',
                message: res.data.msg,
                type: 'success'
                })
          } else {
            this.$notify.error({
            title: '错误',
            message: res.data.msg
           })
          }
      },
   open(){
     window.location.href = '#leaveWrod'
   }
  },
  mounted() {
    if (this.$route.query.active){
        this.open()
    }
  }
}
</script>

<style lang="less">
@import '../fn.less';
    .service_cully{
      margin-bottom: 10.41666vw;
      .vw(padding,0,110);
      box-sizing: border-box;
    }
    .cully{
      display: none;
    }
  .service,.cully{
      margin-top: 10.2395833vw;
      .service_title,.cully_title{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
      }
      .service_cont{
          margin-top: 4.145833vw;
          width: 100%;height: 100%;
          background: #F7F7F7;
          padding: 3.0458333vw 5.0291666vw 2.008333vw;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          .contLeft{
              .contlist{
              margin-bottom: 4.20833vw;
              .listTitle{
                  color: #A6A6A6;
                  font-size: 1.04166;
              }
              .listCont,.tell{
                  color: #171B1D;
                  font-size: 1.5625vw;
                  margin-top: 0.55vw;
                  font-weight: 700;
              }
              .tell{
                  color: #00538D;
              }
          }
          }
          .contRight{
              .message_info{
                  width: 44.2708333vw;
                  display: flex;
                  justify-content: space-between;
                  .userInfo{
                  display: flex;
                  flex-direction: column;
                  margin-right: 1.04166vw;
                  span{
                  color: #171B1D;
                  .vw(font-size,20);
                  margin-bottom: 1.054166vw;
                 }
                 .el-input{
                      .el-input__inner{
                          &::placeholder{
                           .vw(font-size,16);
                            color: #c0c1c5;
                          }
                      }
                  }
                }
              }
              .message_cont{
                  .vw(font-size,20);
                  margin-top: 2.004166vw;
                  textarea{
                      width: 44.270833vw;
                      height: 12.125vw;
                      border: 0px;
                      padding: 0;
                      margin-top: 1.354166vw;
                      padding: 1.40625vw 1.1458333vw;
                      box-sizing: border-box;
                      font-size: 1.04166vw;
                      outline: none;
                  }
                  textarea::placeholder{
                      .vw(font-size,16);
                      color: #c0c1c5;
                  }
              }
              .message_btn{
                  text-align: center;
                  margin-top: 2.0604166vw;
                  button{
                      width: 10.4375vw;
                      height: 3.029166vw;
                      background: #0F6AB4;
                      color: #fff;
                      font-size: 1.04166vw;
                      border: 0px;
                      cursor: pointer;
                    &:hover{
                        opacity: .7;
                    }
                  }
              }
          }
      }
      .cullyList{
          margin-top: 5.416666vw;
          text-align: center;
          display: flex;
          ul{
              width: 100%;
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              li{
              margin-right: 1.04166vw;
              margin-bottom: 1.04166vw;
              img{
              width: 15.625vw;
              height: 9.1145833vw;
             }
           }
           }
          }
         }
    @media screen and (max-width: 980px) {
    .service_cully{
      .vvw(padding,0,0);
      box-sizing: border-box;
    }
     .service{
      .service_cont{
          margin-top: 6.145833vw;
          width: 100%;
          height: 100%;
          background: #F7F7F7;
          padding: 3.6458333vw 5.7291666vw 2.708333vw;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .contLeft{
              .contBox{
                  display: flex;
                  justify-content: space-between;
              }
              .contlist{
              margin-bottom: 5.20833vw;
              .listCont,.tell{
                  color: #171B1D;
                  .vvw(font-size,24);
                  margin-top: 0.55vw;
                  font-weight: 700;
              }
              .tell{
                  color: #00538D;
              }
              }
          }
          .contRight{
              .message_info{
                  width: 100%;
                .userInfo{
                  display: flex;
                  flex-direction: column;
                  margin-right: 1.04166vw;
                  span{
                  .vvw(font-size,24);
                  }
                  .el-input{
                      .el-input__inner{
                          border: 0;
                          .vvw(height,70);
                          .vvw(padding,0,15);
                          &::placeholder{
                            .vvw(font-size,16);
                          }
                      }
                  }
                }
              }
              .message_cont{
                  .vvw(font-size,24);
                  margin-top: 2.604166vw;
                  textarea{
                      width: 100%;
                      height: 19.5510204vw;
                      border: 0px;
                      padding: 0;
                      margin-top: 1.354166vw;
                      padding: 1.40625vw 1.1458333vw;
                      box-sizing: border-box;
                      .vvw(font-size,24);
                      outline: none;
                  }
                  textarea::placeholder{
                  .vvw(font-size,24);
                      color: #A6A6A6;
                  }
              }
              .message_btn{
                  text-align: center;
                  margin-top: 2.7604166vw;
                  button{
                      width: 21.224489vw;
                      height: 6.80204vw;
                      background: #0F6AB4;
                      color: #fff;
                      .vvw(font-size,24);
                      border: 0px;
                      cursor: pointer;
                  }
              }
          }
      }
    }
    .service_title{
        .vvw(padding-right,80)!important;
    }
    .cully_title{
        .vvw(padding-right,80)!important;
    }
    .cullyList{
        .vvw(padding,0,80)!important;
    }
   }
</style>
