<template>
  <div class="sln_frim">
    <div class="sln">
      <div class="slntitle">
         <SectionTitle title="solution"/>
         <SectionSubTitle title="解决方案"/>
      </div>
      <div class="content_box">
        <div class="cont_top">
          <div class="text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {{solution.content}}
          </div>
          <div class="slnimg">
           <img :src="solutionImg[1]" alt="" />
          </div>
        </div>
        <div class="cont_bottom">
          <div class="moreimg"><img :src="solutionImg[0]" alt="" /></div>
          <div class="more">
            <router-link to="/solution"><span>了解更多</span><img class="arrows" src="../../assets/check.png" alt="" /></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="frims">
      <div class="frimstitle">
        <SectionTitle title="branch" :with-highlighted-tail="false" :with-dot="false"/>
        <SectionTitle title="office"/>
        <SectionSubTitle title="公司分布"/>
        <p>第三方区域化消毒供应中心布局图</p>
      </div>
      <div class="map" id="map">
        <div class="mapIng">
          <div class="place" v-for="(item,index) in placeArr" :key="index" :style="'top:' + item.latitude +'vw;left:'+item.longitude+'vw'" @click="goDetails(item.id)">
            <div class="placeBox"  @mouseover='placeShow(item.id)'>
              <img src="../../assets/home/icon.png" alt="">
              <span class="havePlace">{{item.name}}</span>
            </div>
            <div class="placeInfo"  v-show="placeIns[item.id]" @mouseover='placeShow(item.id)' @mouseout='placefalse'>
              <div class="sharp"></div>
              <div class="placeCont">
                <p>{{item.depict}}</p>
                <div v-html="item.content">{{item.content}}</div>
                <span>查看更多</span>
              </div>
            </div>
          </div>
          <!-- 未落地项目 -->
          <div class="place" v-for="(item) in notPass" :key="item.id" :style="'top:' + item.latitude +'vw;left:'+item.longitude+'vw'" @click="goDetails(item.id)">
            <div class="placeBox"  @mouseover='placeShow(item.id)'>
              <span class="not"></span>
              <span class="notPlace">{{item.name}}</span>
            </div>
          </div>
        </div>
        <div class="signNav">
              <div class="">
                <img src="../../assets/home/icon.png" alt="标记">
                已落地项目
              </div>
              <div>
                <span></span>
                待落地项目
              </div>
        </div>
        <img src="../../assets/home/nanhai.jpg" alt="南海" class="nanhaiImg">
      </div>
    </div>
  </div>
</template>

<script>
import { imgHttp, request } from '../../apis/http'
import NavMixin from '@/mixins/nav-minxin'
export default {
  data () {
    return {
      active: '',
      placeArr:[],
      notPass:[],
      hover:false,
      placeIns:[],
      solution:{},
      solutionImg:{}
    }
  },
  mixins: [
    NavMixin
  ],
  beforeMount(){
    this.companyList()
    this.course()
  },
  methods: {
    async companyList () {
      const res = await request({
        url: 'api/index/companylist'
      })
     this.placeArr = res.data.data.filter(i => i.type === '1')
     this.notPass = res.data.data.filter(i => i.type === '2')
      // console.log(this.notPass, '城市列表')
   },
   async course () {
      const res = await request({
        url: '/api/index/course?type=3'
      })
      const imgArr = res.data.data[0].images.split(',').map(img => {
         img = imgHttp + img
         return img
      })
       // 解决方案图片
      this.solutionImg = imgArr
       // 解决方案文档
      this.solution = res.data.data[0]
   },
   goDetails(code){
     this.$router.push({ path:'/mapInfo', query: { code:code } })
   },
   placeShow(id){
      let show = JSON.parse(JSON.stringify(this.placeIns))
      show = []
      show[id] = !show[id]
      this.placeIns = show
   },
   placefalse(id){
     let show = JSON.parse(JSON.stringify(this.placeIns))
      show = [false]
      // show[id] = !show[id]
      this.placeIns = show
   },
   open(){
     window.location.href = '#map'
   }
  },
  mounted() {
    if (this.$route.query.place){
        this.open()
    }
  }
}
</script>
<style lang="less">
@import '../fn.less';
@keyframes arrows-float-gear {
  0% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(-10px);
  }
}
@keyframes place-float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.sln_frim{
  box-sizing: border-box;
  .vw(padding,0,110);
}
.sln .slntitle {
  .vw(margin-top,200)
}
.sln .slntitle,
.frimstitle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  p{
    .vw(font-size,22);
    .vw(margin-top,40);
    font-weight: 700;
  }
}
.content_box {
  margin-top: 3.20833vw;
  .vw(padding,0,110);
  box-sizing: border-box;
  .cont_top {
    display: flex;
    width: 100%;
    color: #fff;
    font-size: 1.04166vw;
    .text {
      width: 44.458333vw;
      height: 16.7395833vw;
      line-height: 2.2395833vw;
      background-color: #00538d;
      box-sizing: border-box;
      .vw(padding,80)
    }
    .slnimg {
      flex: 1;
      height: 16.7395833vw;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: transform 2s;
      &:hover{
        transform: scale(1.1);
        transition: transform 2s;
      }
      }
    }
  }
  .cont_bottom {
    display: flex;
    .moreimg {
      width: 55%;
      height: 12.7395833vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .more{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background:#F8F8F8;
      a{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.58333vw;
      color: #00538d;
      img {
        width: 0.6895833vw;
        height: 0.84166vw;
        margin-top: 1.4270833vw;
      }
    }
    }
  }
}
.not{
          display: inline-block;
          width: 0.5vw;
          height: 0.5vw;
          border-radius: 50%;
          background: rgb(97, 95, 95);
        }
.arrows{
  animation-name: arrows-float-gear;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.frims {
  margin-top: 10.2395833vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .frimstitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title_bottom {
      margin-right: 4.6875vw;
    }
  }
  .map {
    height: 37.770833vw;
    display: flex;
    justify-content: center;
    position: relative;
    .signNav{
      font-size: 1.04166vw;
        position: absolute;
        left: 0;
        bottom: 5vw;
        div{
          display: flex;
          align-items: center;
          .vw(margin-bottom,10);
          img{
          width: 1.0479166vw;
          height: 1.4625vw;
          .vw(margin-right,10);
         }
         span{
          .vw(margin-right,10);
         }
        span,.not{
          display: inline-block;
          width: 1vw;
          height: 1vw;
          border-radius: 50%;
          background: rgb(97, 95, 95);
        }
        }
    }
    .mapIng{
      .vw(width,1000);
      height: 100%;
      margin-top: 4.5729166vw;
      background: url('../../assets/home/frims1.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      .vw(top,-150);
      .vw(left,500);
      .place{
        position: absolute;
        display: flex;
        flex-direction: column;
        font-size: 0.84166vw;
        font-weight: 700;
        cursor: pointer;
        .placeBox{
        width: 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
          width: 1.0479166vw;
          height: 1.4625vw;
          transform: translateY(0px);
          transition: transform 1s;
        }

        }
        &:hover{
          img{
            transform: translateY(-5px);
            transition: transform 1s;
          }
        }
        .placeInfo{
          .vw(width,305);
          display: flex;
          align-items: center;
          position: absolute;
          .vw(left,-370);
          .vw(top,-20);
          position: relative;
          .sharp{
            .vw(width,0);
            .vw(height,0);
            border-top: 1vw solid transparent;
            border-left: 1.5vw solid rgba(0, 83, 141, .8);
            border-bottom: 1vw solid transparent;
            position: absolute;
            z-index: 1;
            .vw(right,-104);
          }
          .placeCont{
            .vw(width,370);
            background: rgba(0, 83, 141, .8);
            position: absolute;
            .vw(left,10);
            .vw(padding,20);
            z-index: 2;
            border-radius: 1.04166vw;
            color: #fff;
            font-size: 0.04166vw;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            p{
              .vw(margin-bottom,16);
              .vw(font-size,16);
            }
            div{
              overflow:hidden;
              text-overflow: ellipsis;
              display: -webkit-box; // 作为弹性伸缩盒子模型显示。
              -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式--从上到下垂直排列
              -webkit-line-clamp: 4; // 显示的行
            }
            span{
              color: #00AEFF;
              .vw(margin-top,10)
            }
          }
        }
      }
    }
  }
}

  .nanhaiImg{
    width: 8vw;
    height: 12vw;
    position: absolute;
    right: 10vw;
    bottom: 3vw;
  }
   @media screen and (max-width: 980px) {
     .notPlace,.havePlace{
       display: none;
     }
.sln_frim{
  box-sizing: border-box;
  .vvw(padding,0,0);
}
.content_box {
  margin-top: 3.20833vw;
  .vvw(padding,0);
  .cont_top {
    display: flex;
    width: 100%;
    color: #fff;
    .vvw(font-size,20);
    position: relative;
    .text {
      width: 100%!important;
      height: 100%;
      line-height: 5.2395833vw;
      box-sizing: border-box;
      padding: 8.673469vw;
      background-color:rgba(0, 83, 141, 0.6)!important;
    }
    .slnimg {
      width: 100%!important;
      height: 100%;
      // background: url('../../assets/solution.jpg') no-repeat;
      // background-position: center;
      // background-size: 100% 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1100;
    }
   }
   .cont_bottom {
    display: flex;
    .moreimg {
      width: 55%;
      height: 28.5714286vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .more{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      a{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .vvw(font-size,24);
      color: #00538d;
      img {
        width: 1.9895833vw;
        height: 2.04166vw;
        margin-top: 1.9270833vw;
      }
    }
    }
  }
  }
  .frims{
    .map{
      .vvw(margin-left,50);
      .signNav{
        .vvw(font-size,24)
      }
    }
  }
  .arrows{
  animation-duration: 0s;
   }
   .frimstitle{
      box-sizing: border-box;
      .vvw(padding-left,50)!important;
      p{
    .vvw(font-size,22);
    .vvw(margin-top,20);
    font-weight: 700;
  }
   }
   .slntitle{
  box-sizing: border-box;
      .vvw(padding-right,50)!important;
   }
  .nanhaiImg{
    width: 8vw;
    height: 12vw;
    position: absolute;
    .vvw(right,150);
    bottom: 3vw;
  }
  }
</style>
