<template>
  <div :class="`section-title-wrapper ${withHighlightedTail ? 'with-highlight-tail' : ''} ${withDot ? 'with-dot' : ''}`">
    <span v-for="(i, ii) in renderingTitle" :key="ii">{{i}}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    withHighlightedTail: {
      required: false,
      type: Boolean,
      default: true
    },
    withDot: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  name: 'SectionTitle',
  data () {
    return {
      renderingTitle: []
    }
  },
  beforeMount () {
    this.renderingTitle = (this.title || '').toUpperCase().split('')
  }
}
</script>

<style lang="less" scoped>
@import '../views/fn.less';
.section-title-wrapper {
  span {
    color: #171B1D;
    font-size: 3.10204082vw;
    font-weight: 800;
    line-height: 2.0041666vw;
    font-family:PuHuiTi;
    letter-spacing: 2px;
  }
  &.with-dot {
    line-height: 3.0041666vw;
    &::after {
      content: '.';
      .vvw(font-size,50)
    }
  }
  &.with-highlight-tail {
    span:last-of-type {
      color: #00538D;
    }
    &::after {
      color: #00538D;
    }
  }
}
</style>
