<template>
  <div class="home">
     <div class="head">
       <Nav nums='0' :isColor=isFFF :isChange=isShow />
         <div class="homebanner">
           <Video :bannerArr='bannerArr'/>
         </div>
     </div>
      <div class="newsCent">
        <div class="newstitle">
         <SectionTitle title="news"/>
         <SectionSubTitle title="新闻中心"/>
         </div>
         <div class="content">
           <div class="contBox">
              <div class="list_cont">
                <ul class="contList">
                <li v-for="(item,index) in homeNews" :key="index" @mouseover="mouseoverCont(item.id)">
                  <router-link :to="{path:`/newsDetails`,query:{id:item.id}}">{{item.name}}</router-link>
                </li>
              </ul>
              </div>
              <div class="cheakMost">
                <router-link to="/news">
                  <span>查看更多</span>
                  <img class="someNews" src="../assets/check.png" alt="">
                </router-link>
              </div>
           </div>
           <div class="contImg" @click="goNews">
             <img :src="newsCon.images" alt="">
                <div class="imgcont">
                  <span>{{newsCon.name}}</span>
                </div>
           </div>
         </div>
       </div>
       <Sln/>
       <Service/>
       <Floor/>
  </div>
</template>

<script>
import Nav from './nav.vue'
import Sln from './home/sln.vue'
import Service from './home/service.vue'
import Floor from './floor.vue'
import NavMixin from '@/mixins/nav-minxin'
import { imgHttp, request } from '../apis/http'
import moment from 'moment'
import Video from '../components/video.vue'

export default {
  components: {
    Nav,
    Sln,
    Service,
    Floor,
    Video
  },
  mixins: [
    NavMixin
  ],
  data () {
    return {
      active: '',
      homeNews:[],
      newsCon:{},
       scroll: '',
       isFFF:'rgba(255,255,255,0.4)',
       i:0,
       isShow:true,
       timeOut:'',
       bannerArr:[],
       bannerVideo:true,
       bannerImg:[]
    }
  },
  beforeMount(){
    this.newHome()
    this.bigBanner()
    scrollTo(0, 0)
    this.conShow = true
  },
  methods: {
    goNews(){
      this.$router.push({ path:'/news' })
    },
    mouseoverCont (id) {
      this.newsInfo(id)
    },
    async newHome () {
      const res = await request({
        url: '/api/index/newslist?type=2'
      })
      const newHomeList = res.data.data.map(i => {
          i.updatetime = moment(i.updatetime * 1000).format('MM.DD')
          i.images = imgHttp + i.images
          return i
      })
      this.homeNews = newHomeList
      this.newsCon = newHomeList[0]
   },
  //  首页新闻详情
  async newsInfo (id) {
      const res = await request({
        url: `/api/index/newsinfo?id=${id}`
      })
      res.data.data.images = imgHttp + res.data.data.images
      res.data.data.createtime = moment(res.data.data.createtime * 1000).format('YYYY.MM.DD')
      this.newsCon = res.data.data
   },
  //  大banner
   async bigBanner () {
      const res = await request({
        url: '/api/index/cover?type=6'
      })
      const bannerNewArr = res.data.data.map(res => {
        if (res.images !== ''){
            res.images = res.images.split(',').map(f => {
            f = imgHttp + f
            return f
          })
          }
          if (res.videos !== ''){
              res.videos = res.videos.split(',').map(r => {
              r = imgHttp + r
              return r
          })
          }
          res.arr = [...res.images, ...res.videos]
        return res
      })
      this.bannerArr = bannerNewArr[0].arr
   }
 },
  mounted() {
    let times
    this.isShow = true
    const _this = this
    window.addEventListener('scroll', function(){
      _this.scroll = document.documentElement.scrollTop || document.body.scrollTop
       if (_this.scroll <= 200){
          _this.isFFF = 'rgba(255,255,255,0.4)'
          _this.isShow = true
          clearTimeout(times)
        } else {
          _this.isFFF = '#fff'
           _this.isShow = true
          clearTimeout(times)
          times = setTimeout(function(){
          _this.isShow = false
           }, 2000)
        }
    }, false)
  }
}
</script>
<style lang="less">
@import './fn.less';
@keyframes someNews {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(30px);
  }
  100% {
    transform: translateX(0px);
  }
}
 /*修改播放器样式*/
  .head{
    width: 100%;
    height: 46vw;
    position: relative;
    .homebanner{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .el-carousel .el-carousel__indicators{
        flex-direction: row;
      }
    }
  }
  .video{
    width: 100%;
    height: 46vw;
  }
  .newsCent{
    width: 100%;
    margin-top: 10.2395833vw;
    box-sizing: border-box;
    .vw(padding,0,110);
    .content{
      width:100%;
      margin-top: 4.270833vw;
      display: flex;
      .contBox{
        width: 38.25vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .cheakMost a{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 1.04166vw;
          color: #00538D;
            padding-left:2.854166vw;
          span{
            display: inline-block;
            border-bottom: 1px solid #00538D;
          }
          img{
            width: 0.9895833vw;
            height: 1.04166vw;
            margin-left: 0.9375vw;
          }
        }
        .list_cont{
          .contList{
          width: 100%;
          max-height: 27vw;
          overflow: hidden;
          position: absolute;
          z-index: 1;
          left: 0;
            li{
            min-width: 38.25vw;
            box-sizing: border-box;
            padding-left:2.854166vw;
            height: 3.3875vw;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1.04166vw;
            a{
            width: 100%;
            }
           }
           li:hover{
            width: 38.25vw;
            font-size:1.5625vw;
            background: linear-gradient(to left, rgba(0,83,414,0) -40%,rgba(0,83,141,1) 60%);
             a{
              color:#fff;
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
             }
           }
        }
      }

      }
      .contImg{
        flex: 1;
        height: 30vw;
        position: relative;
        img{
          width: 100%;
          height: 100%;
        }
        .imgcont{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-height: 5.0291666vw;
          background: rgba(0,83,141,.7);
          position: absolute;
          bottom: 0;
          color: #fff;
          font-size: 1.58333vw;
          font-weight: 700;
          padding: 0.86875vw 2.729166vw 0.86875vw 5.729166vw;
          box-sizing: border-box;
        }
      }
    }
  }
  .cheakMost:hover .someNews{
   animation-name: someNews;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .cheakMost:hover .someNews{
    animation-name: someNews;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
   @media screen and (max-width: 980px) {
    body{
      min-width: 100%;
    }
  .head{
    width: 100%;
    .vvw(height,545);
    .homebanner{
      margin-top: 10vw;
    }
  }
  .newsCent{
    width: 100%;
    margin-top: 10vw;
    box-sizing: border-box;
    .vvw(padding,0,0);
    .titleNews{
      font-weight: 700;
      margin-left:4.729166vw;
    }
    .content{
      width:100%;
      margin-top: 2.270833vw;
      display: flex;
      .contBox{
        display: none;
        .cheakMost{
          display: none;
        }
      }
      .contImg{
        width: 60vw;
        .vvw(height,500);
        position: relative;
        .vvw(margin-top,80);
        img{
          min-width: 26.04166vw;
          width: 100%;
          height: 100%;
        }
        .imgcont{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          min-height: 5.7291666vw;
          background-color: rgba(0, 83, 141, 0.7);
          position: absolute;
          bottom: 0;
          color: #fff;
          .vvw(font-size,36);
          font-weight: 700;
          padding-right: 2.729166vw;
          padding-left: 2.729166vw;
          padding-top: 1.96875vw;
          padding-bottom: 1.96875vw;
          box-sizing: border-box;
        }
      }
    }
    .newstitle{
      .vvw(padding-left,50)!important;
    }
  }
  }
</style>
