<template>
 <div>
     <el-carousel ref="nop" height="46vw" :interval='5000' :arrow="bannerArr.length==1?'never':'hover'" :autoplay='isAuto'  @change="((pre, next) => {change(pre, next, 0)})">
              <el-carousel-item v-for="(item,index) in bannerArr" :key="index" style="background-color:#fff;">
               <div class="video" v-if="item.indexOf('mp4')!=-1" @mouseenter="conShow=true" @mouseleave="conShow=false">
                    <video
                    style="object-fit: fill;width=100%;height:100%;"
                    :id="'myVideo' + index"
                    :src="item"
                    @ended="videoEnd"
                    @play="isAuto=false"
                    class="video-js vjs-big-play-centered vjs-fluid">
                </video>
                <img v-show="conShow" class="control" :src="isPlay" alt="" @click="changeControl">
               </div>
                <img v-if="item.indexOf('mp4')==-1" :src="item" alt="轮播图" style="width:100%;height:100%">
              </el-carousel-item>
            </el-carousel>
 </div>
</template>

<script>
 export default {
     props:{
         bannerArr:{
             type:Array
         }
     },
   data () {
     return {
      isAuto:true,
      conShow:false,
      isPlay:require('../assets/pause.png'),
      clickChang:false
     }
   },
   beforeMount(){
    this.conShow = true
   },
   components: {

   },
   methods: {
    videoEnd(){
        this.$refs.nop.next()
        this.isAuto = true
    },
    change (pre, next, index) {
        const videoArr = document.getElementsByClassName('video-js')
        for (let i = 0; i < videoArr.length; i++){
            videoArr[i].pause()
            for (let j = 0; j < videoArr.length; j++){
                if (pre === j){
                    if (this.clickChang){
                    videoArr[j].play()
                    this.isPlay = require('../assets/play.png')
                    }
                } else {
                    this.isPlay = require('../assets/pause.png')
                }
            }
        }
    },
    changeControl(){
        this.clickChang = !this.clickChang
        if (this.clickChang){
          document.getElementById('myVideo0').play()
                    this.isPlay = require('../assets/play.png')
        }
        if (!this.clickChang){
          document.getElementById('myVideo0').pause()
          this.isPlay = require('../assets/pause.png')
          this.isAuto = true
        }
    }

   },
   watch: {

   },
   computed: {

   }
 }
</script>

<style scoped lang='less'>
  .video{
      position: relative;
      .control{
          width: 4vw;
          height: 4vw;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -2vw;
          margin-top: -2vw;
          z-index: 50000;
      }
  }

</style>
