import SectionTitle from '@/components/SectionTitle'
import SectionSubTitle from '@/components/SectionSubTitle'
import nav from '@/views/nav'
import Floor from '@/views/floor'

const NavMixin = {
  components: {
    Nav: nav,
    Floor,
    SectionTitle,
    SectionSubTitle
  }
}

export default NavMixin
